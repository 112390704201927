import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
 
const SingleBlog = (props) => {
    return (
        <Layout>

<div class="bg-white pb-6 pt-10 sm:py-8 lg:py-12 lg:pt-52">
  <div class="max-w-screen-xl px-4 md:px-8 mx-auto">
    <div class="grid md:grid-cols-2 gap-8 lg:gap-12">
      <div>
        <div class=" h-64 md:h-auto bg-gray-100 overflow-hidden rounded-lg shadow-lg">

        <GatsbyImage image={props.data.contentfulGallery.img.gatsbyImageData} alt="blog-image" class="w-full h-full object-cover object-center" />

        </div>
      </div>

      <div class="md:pt-8">

        <h1 class="text-gray-800 text-3xl sm:text-4xl break-words	en text-center md:text-left mb-4 md:mb-6">{props.data.contentfulGallery.title}</h1>

        <p class="text-gray-500 sm:text-lg mb-6 md:mb-8 break-words	">
        <span class="text-xs text-gray-400 ">Year : </span>{props.data.contentfulGallery.year}<br />
        <span class="text-xs text-gray-400 ">Media : </span>{props.data.contentfulGallery.category}<br />
        <span class="text-xs text-gray-400">Charged : </span>{props.data.contentfulGallery.charge}
        </p>
        
        <p class="text-blue-400 sm:text-lg mb-6 md:mb-8 break-all">{renderRichText(props.data.contentfulGallery.url)}</p>

        <h2 class="text-gray-800 text-2xl sm:text-3xl  en text-center md:text-left mb-2 md:mb-4">Impression</h2>
        <p class="text-gray-500 leading-loose text-sm mb-6 md:mb-8">{renderRichText(props.data.contentfulGallery.impression)}</p>
        <AniLink swipe to="/" class=" px-10 py-2  text-base rounded-full text-blue-400 border border-blue-400 undefined ">
<FontAwesomeIcon icon={faArrowLeft} /> Back</AniLink>

      </div>
    </div>
  </div>
</div>





















</Layout>
    )
}

export default SingleBlog

export const query = graphql`
query ContentfulSingleGalleryQuery($slug: String!) {
    contentfulGallery (slug: { eq: $slug }) {
        title
        year
        category
        charge
        id
        impression {
          raw
        }
        url {
          raw
        }
        img {
          gatsbyImageData(formats:  [AUTO, WEBP, AVIF], placeholder: BLURRED, quality: 90)
        }
    }
}

`                             
